<template>
  <q-card class="l-wrapper" flat square bordered>
    <q-expansion-item v-model="expanded" class="bg-grey-4">
      <template v-slot:header>
        <q-item-section side>
          <q-btn
            flat
            dense
            icon="arrow_back"
            @click.stop.prevent="router.go(-1)"
          ></q-btn>
        </q-item-section>
        <q-item-section>
          <span class="text-bold text-body1">
            {{ selTest.item_name }}
          </span>
          <span> Request Status : {{ selTest.request_status }} </span>
          <span>
            Dibuat : {{ moment(selTest.created).format("DD MMM YYYY") }}
          </span>
          <span>
            Deadline : {{ moment(selTest.deadline).format("DD MMM YYYY") }}
          </span>
        </q-item-section>

        <q-item-section side>
          <div class="row items-center q-gutter-xs">
            <q-btn
              round
              flat
              dense
              size="md"
              color="primary"
              icon="build"
              v-can="['PURCHASING']"
              @click.stop.prevent="
                router.push(`/app/test/form/${route.params.id}`)
              "
            >
              <q-tooltip>
                Edit Data
              </q-tooltip>
            </q-btn>
          </div>
        </q-item-section>
      </template>
      <q-card class="bg-grey-3 text-black">
        <q-card-section>
          <q-markup-table flat wrap-cells class="my-normal-table">
            <tr>
              <td>Suplier Name</td>
              <td>:</td>
              <td>{{ selTest.supplier_name }}</td>
            </tr>
            <tr v-if="selTest?.rnd_name">
              <td>PIC</td>
              <td>:</td>
              <td>{{ selTest.rnd_name }}</td>
            </tr>
            <tr v-if="selTest?.rnd_name">
              <td>Diproses</td>
              <td>:</td>
              <td>
                {{ moment(selTest.responded).format("HH:MM, DD-MMM-YYYY") }}
              </td>
            </tr>
            <tr>
              <td>Request Note</td>
              <td>:</td>
              <td>{{ selTest.note }}</td>
            </tr>
            <tr v-if="selTest.request_status == 'DECLINED'">
              <td>Declined Note</td>
              <td>:</td>
              <td>{{ selTest.declined_note }}</td>
            </tr>
            <tr v-if="selTest?.development_end">
              <td>Development Done</td>
              <td>:</td>
              <td>
                {{ moment(selTest.development_end).format("DD-MMM-YYYY") }}
              </td>
            </tr>
          </q-markup-table>
        </q-card-section>
        <q-card-actions
          align="stretch"
          v-if="selTest.request_status == 'REQUEST'"
        >
          <q-btn
            class="full-width q-mb-sm"
            color="positive"
            label="ACCEPT"
            v-can="['RND_SUPERVISOR']"
            @click="acceptRequest('accept')"
          ></q-btn>
          <q-btn
            class="full-width"
            color="negative"
            label="REJECT"
            v-can="['RND_SUPERVISOR']"
            @click="acceptRequest('reject')"
          ></q-btn>
        </q-card-actions>
        <q-card-actions
          align="stretch"
          v-else-if="selTest.request_status == 'PROCESS'"
        >
          <q-btn
            class="full-width"
            color="primary"
            label="Update Request Status"
            v-can="['RND']"
            @click="changeStatus"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-expansion-item>
    <q-tabs
      dense
      v-model="tab"
      class="text-black"
      align="justify"
      @update:model-value="getList"
    >
      <q-tab
        :class="tab == 'chat' ? 'bg-blue-8' : 'bg-primary'"
        name="chat"
        label="Chat"
      />
      <q-tab
        :class="tab == 'record' ? 'bg-blue-8' : 'bg-primary'"
        name="record"
        label="Record"
        :disable="selTest.request_status == 'REQUEST' || curUser.is_rnd == 0"
      />
    </q-tabs>
    <q-tab-panels v-model="tab">
      <q-tab-panel class="q-pa-none" name="chat">
        <q-card-section>
          <q-scroll-area
            ref="chatAreaRef"
            class="l-grow q-pa-md bg-grey-3"
            style="height: calc(100vh - 350px)"
          >
            <div v-for="(item, i) in listChat" :key="i">
              <q-chat-message
                :label="
                  item.date == 'TODAY' || item.date == 'YESTERDAY'
                    ? item.date
                    : moment(item.date).format('DD MMM YYYY')
                "
              />
              <q-chat-message
                text-html
                v-for="val in item.chats"
                :key="val.id"
                :name="val.username"
                :bg-color="val.is_sent ? 'green-4' : 'blue-4'"
                :text="[`${val.content}`]"
                :stamp="val.created"
                :sent="val.is_sent"
              />
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-card-section class="q-py-none">
          <q-input
            outlined
            type="textarea"
            placeholder="Type here..."
            v-model="chat"
            rows="3"
          >
          </q-input>
        </q-card-section>
        <q-card-actions class="q-px-md" align="stretch">
          <q-btn
            class="full-width no-border-radius"
            color="primary"
            label="KIRIM PESAN"
            @click="sendChat"
            :disable="chat == ''"
          ></q-btn>
        </q-card-actions>
      </q-tab-panel>
      <q-tab-panel class="q-pa-none" name="record">
        <q-card-section>
          <q-scroll-area
            ref="recordAreaRef"
            class="l-grow q-pa-md bg-grey-3"
            style="height: calc(100vh - 330px)"
          >
            <div v-for="(item, i) in listRecord" :key="i">
              <q-chat-message
                :label="
                  item.date == 'TODAY' || item.date == 'YESTERDAY'
                    ? item.date
                    : moment(item.date).format('DD MMM YYYY')
                "
              />

              <q-chat-message
                text-html
                v-for="val in item.records"
                :key="val.id"
                :name="val.username"
                :bg-color="val.is_sent ? 'green-4' : 'blue-4'"
                :stamp="
                  `${val.created} &nbsp - &nbsp Last Edited : ${moment(
                    val.last_edit
                  ).format('DD/MM/YY HH:mm:ss')}`
                "
                :sent="val.is_sent"
                :text="[val.content]"
                @click="showEditDilaog(val)"
              >
              </q-chat-message>
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-card-section class="q-py-none">
          <div class="q-mb-sm full-width" style="max-width: 100vw;">
            <ckeditor
              id="myEditor"
              :editor="options.editor"
              v-model="options.editorData"
              :config="options.editorConfig"
            ></ckeditor>
          </div>
          <q-uploader
            ref="uploadRef"
            :factory="uploader"
            hide-upload-btn
            label="Upload File"
            accept="image/* , .rar , .zip"
            flat
            bordered
            :max-files="1"
            style="width: 100%"
            @removed="selTest.filename = null"
          />
        </q-card-section>
        <q-card-actions class="q-px-md" align="stretch">
          <q-btn
            class="full-width no-border-radius"
            color="primary"
            label="Simpan Record"
            @click="sendRecord"
            v-can="['RND']"
            :disable="options.editorData == ''"
          ></q-btn>
        </q-card-actions>
      </q-tab-panel>
    </q-tab-panels>
    <q-dialog v-model="modalRecord">
      <q-card style="min-width:1024px">
        <q-card-section>
          <!-- <div class="bg-grey-3 q-pa-md" v-html="modalRecordData.content"></div> -->
          <ckeditor
            id="myEditor"
            :editor="options.editor"
            v-model="modalRecordData.content"
            :config="options.editorConfig"
          ></ckeditor>
        </q-card-section>
        <q-card-actions align="right">
          <!-- <q-btn unelevated color="negative" label="Hapus"></q-btn> -->
          <q-btn
            unelevated
            color="primary"
            label="Edit"
            @click="editRecord"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import { computed, inject, ref, reactive } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import DialogAccepRequest from "../component/DialogAcceptRequest.vue";
import DialogUbahStatus from "../component/DialogUbahStatus.vue";
import DialogFinishRequest from "../component/DialogFinishRequest.vue";

export default {
  setup() {
    const $http = inject("$http");
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();

    let tab = ref("chat");
    let selTest = ref({});
    let curUser = ref({ id: 1 });
    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      // checkParams();
      await getTestDetail();
      await getChat();
    });

    let getList = async (value) => {
      if (value == "chat") {
        await getChat();
      } else if (value == "record") {
        await getRecord();
      }
    };

    let getTestDetail = async () => {
      let resp = await $http.get(`/test/detail/${route.params.id}`);
      selTest.value = resp.data;
    };

    let scrollToEnd = async () => {
      if (tab.value == "chat") {
        await scrollChat();
      } else if (tab.value == "record") {
        await scrollRecord();
      }
    };

    let refreshList = async () => {
      if (tab.value == "chat") {
        await getChat();
      } else if (tab.value == "record") {
        await getRecord();
      }
    };

    let uploader = async (files) => {
      let ext = files[0].name.split(".")[1];
      const d = new Date();
      let newname = `testing_${selTest.value.item_name.replaceAll(
        " ",
        "_"
      )}_${d.getTime()}.${ext}`;
      selTest.value.filename = newname;
      var formData = new FormData();
      formData.append("file", files[0]);
      formData.append("nama", newname);

      await $http.post("/upload.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return {};
    };

    let acceptRequest = (tipe) => {
      let bundle = {
        tipe: tipe,
        detail: selTest.value,
        req_type: "test",
        user: JSON.parse(JSON.stringify(curUser.value)),
      };
      $q.dialog({
        component: DialogAccepRequest,
        componentProps: {
          data: bundle,
        },
      }).onOk(async (resp) => {
        let content = "";
        if (tipe == "accept")
          content = `Test Request Untuk Item : ${selTest.value.item_name}, Supplier : ${selTest.value.supplier_name} Diterima`;
        if (tipe == "reject")
          content = `Test Request Untuk Item : ${selTest.value.item_name}, Supplier : ${selTest.value.supplier_name} Ditolak`;
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: selTest.value.id_user,
          router_to: `/app/test/chat/${selTest.value.id}`,
          content: content,
        };
        await $http.post(`/notification/add`, notifPayload);
        await getTestDetail();
        await getChat();
      });
    };

    let changeStatus = () => {
      $q.dialog({
        component: DialogUbahStatus,
        componentProps: {
          detail: selTest.value,
          req_type: "test",
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: selTest.value.id_user,
          router_to: `/app/test/chat/${selTest.value.id}`,
          content: `Test Material ${selTest.value.item_name}, Customer : ${selTest.value.supplier_name} Telah Selesai`,
        };
        await $http.post(`/notification/add`, notifPayload);
        await getTestDetail();
        await refreshList();
      });
    };

    let finishRequest = () => {
      $q.dialog({
        component: DialogFinishRequest,
        componentProps: {
          detail: selTest.value,
          req_type: "test",
          user: JSON.parse(JSON.stringify(curUser.value)),
        },
      }).onOk(async (resp) => {
        await getTestDetail();
        await refreshList();
      });
    };

    //chat area
    let getChat = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await $http.get(`/chat/list/${route.params.id}/0`);
      for (let item of resp.data) {
        if (item.date == moment().format("YYYY-MM-DD")) {
          item.date = "TODAY";
        }
        if (
          item.date ==
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        ) {
          item.date = "YESTERDAY";
        }
        for (let chat of item.chats) {
          if (chat.id_user == curUser.value.id) {
            chat.is_sent = true;
          } else {
            chat.is_sent = false;
          }
          chat.created = moment(chat.created).format("HH:mm");
        }
      }
      listChat.value = resp.data;
      scrollToEnd();
      $q.loading.hide();
    };

    const chatAreaRef = ref(null);
    let scrollChat = async () => {
      if (tab.value != "chat") return;
      let resp = await chatAreaRef.value.getScroll();
      chatAreaRef.value.setScrollPosition("vertical", 99999);
    };

    let sendChat = async () => {
      let processedChat = chat.value.replaceAll("\n", "<br>");

      let bundle = {
        content: processedChat,
        id_user: curUser.value.id,
        id_request: route.params.id,
        is_development: 0,
      };
      try {
        await $http.post(`/chat/add`, bundle);
        await notifChat();
        chat.value = "";
        $q.notify({ message: "Pesan Berhasil Terkirim!", color: "positive" });
        await getChat();
      } catch (error) {
        $q.notify({ message: "Pesan Gagal Terkirim!", color: "negative" });
      }
    };

    let notifChat = async () => {
      if (curUser.value.is_purchasing == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd"],
          content: `Ada Pesan Baru untuk Item ${selTest.value.item_name}, Customer : ${selTest.value.supplier_name}`,
          router_to: `/app/test/chat/${selTest.value.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);
      }
      if (curUser.value.is_manager == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          term: ["is_rnd"],
          content: `Ada Pesan Baru untuk Item ${selTest.value.item_name}, Customer : ${selTest.value.supplier_name}`,
          router_to: `/app/test/chat/${selTest.value.id}`,
        };
        await $http.post(`/notification/broadcastrole`, notifPayload);

        notifPayload = {
          id_from: curUser.value.id,
          id_to: selTest.value.id_user,
          router_to: `/app/test/chat/${selTest.value.id}`,
          content: `Ada Pesan Baru untuk Item ${selTest.value.item_name}, Customer : ${selTest.value.supplier_name}`,
        };
        await $http.post(`/notification/add`, notifPayload);
      }
      if (curUser.value.is_rnd == 1) {
        let notifPayload = {
          id_from: curUser.value.id,
          id_to: selTest.value.id_user,
          router_to: `/app/test/chat/${selTest.value.id}`,
          content: `Ada Pesan Baru untuk Item ${selTest.value.item_name}, Customer : ${selTest.value.supplier_name}`,
        };
        await $http.post(`/notification/add`, notifPayload);
      }
    };

    let chat = ref("");
    let listChat = ref([]);

    //record area
    let options = reactive({
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "outdent",
            "indent",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    });

    let uploadRef = ref(null);

    let sendRecord = async () => {
      await uploadRef.value.upload();
      let bundle = {
        content: options.editorData,
        id_request: route.params.id,
        id_user: curUser.value.id,
        is_development: 0,
        last_edit: moment().format("YYYY-MM-DD HH:mm:ss"),
      };

      if (selTest.value.filename) {
        if (selTest.value.filename != "") {
          // TODO ganti link file saat mau di hosting
          bundle.content = `${bundle.content} <br><br>Download Attachment <a href="https://forte.rnd.lignosoftware.com/exp_records/${selTest.value.filename}" target="_blank" >disini!</a>`;
          // bundle.content = `${bundle.content} <br><br>Download Attachment <a href="http://localhost/forteapp-rnd/public/exp_records/${selTest.value.filename}">disini!</a>`;
        }
      }

      try {
        await $http.post(`/exp/add-record`, bundle);
        options.editorData = "";
        $q.notify({
          message: "Experiment Record Berhasil Terkirim!",
          color: "positive",
        });
        uploadRef.value.reset();
        await getRecord();
      } catch (error) {
        $q.notify({
          message: "Experiment Record Gagal Terkirim!",
          color: "negative",
        });
      }
    };

    let listRecord = ref([]);
    let getRecord = async () => {
      $q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await $http.get(`/exp/list/${route.params.id}/0`);
      for (let item of resp.data) {
        if (item.date == moment().format("YYYY-MM-DD")) {
          item.date = "TODAY";
        }
        if (
          item.date ==
          moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        ) {
          item.date = "YESTERDAY";
        }
        for (let record of item.records) {
          if (record.id_user == curUser.value.id) {
            record.is_sent = true;
          } else {
            record.is_sent = false;
          }
          record.created = moment(record.created).format("HH:mm");
          // record.content = unescape(content);
        }
      }
      listRecord.value = resp.data;
      scrollToEnd();
      $q.loading.hide();
    };

    const recordAreaRef = ref(null);
    let scrollRecord = async () => {
      let resp = await recordAreaRef.value.getScroll();
      // console.log(resp);
      recordAreaRef.value.setScrollPosition("vertical", 99999);
    };

    let showEditDilaog = (val) => {
      if (val.id_user != curUser.value.id) return;
      modalRecordData.value = JSON.parse(JSON.stringify(val));
      modalRecord.value = true;
    };

    let editRecord = async () => {
      let payload = {
        id: modalRecordData.value.id,
        content: modalRecordData.value.content,
        last_edit: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      try {
        await $http.post(`/exp/edit`, payload);
        $q.notify({
          message: "Experiment Record Berhasil Diubah!",
          color: "positive",
        });
        await getRecord();
      } catch (error) {
        $q.notify({
          message: "Experiment Record Gagal Diubah!",
          color: "negative",
        });
      }
    };

    let modalRecordData = ref({});
    let modalRecord = ref(false);

    return {
      tab,
      expanded: ref(false),
      chat,
      route,
      router,
      selTest,
      listChat,
      curUser,
      sendChat,
      moment,
      options,
      sendRecord,
      getRecord,
      listRecord,
      getList,
      chatAreaRef,
      recordAreaRef,
      scrollRecord,
      modalRecordData,
      modalRecord,
      acceptRequest,
      changeStatus,
      finishRequest,
      showEditDilaog,
      editRecord,
      uploader,
      uploadRef,
    };
  },
};
</script>
<style lang="scss">
table,
td,
th {
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.my-normal-table {
  table,
  td,
  th {
    border: 0px solid red;
  }
}
</style>
